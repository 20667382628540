<template>
  <div>
    <h2 style="text-align: center;">{{$t('system.company')}}</h2>
    <h3 style="text-align: center;">{{  }}</h3>
    <h4 style="text-align: center;">{{$t('All.通知单号')}}:{{formData.Numbers}}</h4>
    <h4 style="text-align: center;">{{$t('All.开单日期')}}:{{formData.Date}}</h4>
    <el-form ref="elForm" :model="formData" :rules="rules" style="max-width: 800px;margin: auto;" size="medium" label-width="90px"
      label-position="top">
      <el-form-item :label="$t('system.部门')" prop="Product_item">
        <el-select v-model="formData.Department" allow-create filterable :placeholder="this.$t('All.请选择')" :style="{width: '100%'}">
          <el-option
            v-for="item in $PublicJs.testdepartment"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="this.$t('All.工单号')" prop="Gongdan">
        <el-input v-model="formData.Gongdan" :placeholder="this.$t('All.实验工单提醒')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="this.$t('All.品名') + '/' + this.$t('All.料号')" prop="Product_item">
        <el-input v-model="formData.Product_item" :placeholder="this.$t('All.请输入品名料号')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="this.$t('All.客户') + '/' + this.$t('All.厂商')" prop="Customer_manufacturer">
        <el-input v-model="formData.Customer_manufacturer" :placeholder="this.$t('All.请输入客户厂商')" clearable
          :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="this.$t('system.实验名称')" prop="Experiment_name">
        <el-input v-model="formData.Experiment_name" :placeholder="this.$t('All.请输入实验名称')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="this.$t('All.样品数量')" prop="Quantity">
        <el-input v-model="formData.Quantity" :placeholder="this.$t('All.请输入样品数量')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="this.$t('system.紧急状况')" prop="Urgent_state">
        <el-radio-group v-model="formData.Urgent_state" size="medium">
          <el-radio v-for="(item, index) in Urgent_stateOptions" :key="index" :label="item.value"
            :disabled="item.disabled">{{item.label}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="this.$t('All.含卤情况')" prop="Halogen">
        <el-radio-group v-model="formData.Halogen" size="medium">
          <el-radio v-for="(item, index) in HalogenOptions" :key="index" :label="item.value"
            :disabled="item.disabled">{{item.label}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="this.$t('All.实验要求') + '/' + this.$t('All.条件')" prop="Requirements">
        <el-input v-model="formData.Requirements" type="textarea" :placeholder="this.$t('All.实验要求') + '/' + this.$t('All.条件') + this.$t('All.不得超过6行')"
          :autosize="{minRows: 6, maxRows: 6}" :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="this.$t('All.参考文件')">
        <el-upload list-type="picture-card" :action="`${$Upload}/api/Laboratory/File_user_upload/`" :data="filedata" :on-success="handsuccess" :file-list="fileList">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="xsimg(file)" :alt="file.name">
                <span class="el-upload-list__item-actions" :alt="file.name">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleDownload(file)">
                        <i class="el-icon-download"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                    </span>
                </span>
                <p>{{file.name}}</p>
            </div>
            <el-dialog :visible.sync="dialogVisible" append-to-body>
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
        </el-upload>
      </el-form-item>
      <el-form-item :label="this.$t('All.备注')">
        <el-input v-model="formData.Remarks" type="textarea" :placeholder="this.$t('All.请输入备注')"
          :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item size="large">
        <el-button type="primary" @click="submitForm">{{ $t('All.提交') }}</el-button>
        <el-button @click="resetForm">{{ $t('All.重置') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  components: {
  },
  data () {
    return {
      formData: {
        Date: '',
        Numbers: '',
        Product_item: '',
        Customer_manufacturer: '',
        Experiment_name: '',
        Quantity: '',
        Urgent_state: this.$t('All.普通'),
        Requirements: '',
        Remarks: '',
        Applicant: this.$store.state.Login.User,
        Applicantname: this.$store.state.Login.Username,
        Factory: this.$store.state.Login.Factory,
        Department: this.$store.state.Login.Department,
        Receiver: '',
        Receivername: '',
        Reviewer: '',
        Reviewername: '',
        External: 0,
        Approver: '',
        Approvername: '',
        Filename: '',
        Fileurl: '',
        State: 0,
        Theredays: 0,
        Statename: '',
        Gongdan: '',
        Nextname: this.$t('All.等待实验室接收'),
        Outreachtesting: 0
      },
      rules: {
        Date: [{
          required: true,
          message: this.$t('All.请输入开单日期'),
          trigger: 'blur'
        }],
        Gongdan: [{
          required: true,
          message: this.$t('All.IQC请输入采购单号工程QE请输入项目号其它QC请输入工单号'),
          trigger: 'blur'
        }],
        Numbers: [{
          required: true,
          message: this.$t('All.请输入通知单号'),
          trigger: 'blur'
        }],
        Product_item: [{
          required: true,
          message: this.$t('All.请输入品名料号'),
          trigger: 'blur'
        }],
        Customer_manufacturer: [{
          required: true,
          message: this.$t('All.请输入客户厂商'),
          trigger: 'blur'
        }],
        Experiment_name: [{
          required: true,
          message: this.$t('All.请输入实验名称'),
          trigger: 'blur'
        }],
        Quantity: [{
          required: true,
          message: this.$t('All.请输入样品数量且只能输入数字'),
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d+)?$/,
          message: this.$t('All.只能输入数字'),
          trigger: 'blur'
        }],
        Urgent_state: [{
          required: true,
          message: this.$t('All.紧急状况不能为空'),
          trigger: 'change'
        }],
        Halogen: [{
          required: true,
          message: this.$t('All.含卤情况不能为空'),
          trigger: 'change'
        }],
        Requirements: [{
          required: true,
          message: this.$t('All.请输入实验要求条件'),
          trigger: 'blur'
        }]
      },
      Urgent_stateOptions: [{
        label: this.$t('All.特急'),
        value: this.$t('All.特急')
      }, {
        label: this.$t('All.急'),
        value: this.$t('All.急')
      }, {
        label: this.$t('All.普通'),
        value: this.$t('All.普通')
      }],
      HalogenOptions: [{
        label: 'HF',
        value: 'HF'
      }, {
        label: 'HSF',
        value: 'HSF'
      }],
      fileList: [],
      filedata: {
        Numbers: '',
        Type: 0,
        User: this.$store.state.Login.User,
        Username: this.$store.state.Login.Username,
        Factory: this.$store.state.Login.Factory
      },
      dialogVisible: false,
      dialogImageUrl: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    this.LaCount()
  },
  props: ['addTab', 'RemoveTab', 'Renewtargename'],
  mounted () {},
  methods: {
    ...mapMutations(['TabComponentFun']),
    async LaCount () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/LaCount', this.$store.state.Login)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.formData.Numbers = res.response
        this.formData.Date = this.getCurrentTime()
        this.filedata.Numbers = res.response
        this.LaUserGetAllFiles(res.response)
      } catch (error) {
        loadingInstance.close()
        console.log(error)
      }
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      // 时
      const hour = new Date().getHours()
      // 分
      const minute = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      // 秒
      const second = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()

      const gettime = yy + '-' + mm + '-' + dd + ' ' + hour + ':' + minute + ':' + second
      return gettime
    },
    async handsuccess (response) {
      const data = {
        name: '',
        url: ''
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = response.url
      this.fileList.splice(0, 0, data)
    },
    async handleRemove (file) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        var row = {
          Name: file.name,
          Type: this.Type,
          Numbers: this.filedata.Numbers
        }
        const { data: res } = await this.$http.post('/api/Laboratory/File_user_del', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.fileList.some((item, i) => {
          if (item.name === file.name) {
            this.fileList.splice(i, 1)
          }
        })
        this.$message.success(`${this.$t('All.删除')}${file.name}${this.$t('All.成功')}！`)
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async LaUserGetAllFiles (row) {
      try {
        const { data: res } = await this.$http.get('/api/Laboratory/LaUserGetAllFiles?Number=' + row + '&Factory=' + this.$store.state.Login.Factory)
        if (res.status !== 200) return
        this.fileList = res.response
      } catch (error) {
        alert(error)
      }
    },
    xsimg (val) {
      var index = val.name.lastIndexOf('.')
      const obj = val.name.substring(index + 1, val.name.length)
      const objj = obj.toUpperCase()
      switch (objj) {
        case 'JPEG':
          return val.url
        case 'BMP':
          return val.url
        case 'TIFF':
          return val.url
        case 'GIF':
          return val.url
        case 'PNG':
          return val.url
        case 'JPG':
          return val.url
        case 'PDF':
          return require('@/assets/Img/pdf.jpg')
        case 'XLS':
          return require('@/assets/Img/excle.jpg')
        case 'XLSX':
          return require('@/assets/Img/excle.jpg')
        case 'RAR':
          return require('@/assets/Img/rar.jpeg')
        case 'ZIP':
          return require('@/assets/Img/rar.jpeg')
        default:
          return require('@/assets/Img/nullimg.jpeg')
      }
    },
    submitForm () {
      this.$refs.elForm.validate(async valid => {
        if (!valid) return this.$message.error(this.$t('All.请完善表单'))
        // TODO 提交表单
        const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
        try {
          const { data: res } = await this.$http.post('/api/Laboratory/Laboratryadd', this.formData)
          if (res.status !== 200) {
            loadingInstance.close()
            return this.$message.error(res.response)
          }
          loadingInstance.close()
          this.Renewtargename(this.$t('All.实验室') + this.$t('All.待审区'), 'TestExamine', 'Lagetflow')
          this.RemoveTab(this.$t('All.送测单'))
        } catch (error) {
          loadingInstance.close()
          console.log(error)
          alert(this.$t('All.网络异常'))
        }
      })
    },
    resetForm () {
      this.$refs.elForm.resetFields()
    }
  }
}

</script>
<style>
</style>
